// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-posts-contentful-post-pathname-jsx": () => import("./../../../src/pages/posts/{contentfulPost.pathname}.jsx" /* webpackChunkName: "component---src-pages-posts-contentful-post-pathname-jsx" */),
  "component---src-pages-posts-index-jsx": () => import("./../../../src/pages/posts/index.jsx" /* webpackChunkName: "component---src-pages-posts-index-jsx" */),
  "component---src-pages-projects-colour-clock-jsx": () => import("./../../../src/pages/projects/colour-clock.jsx" /* webpackChunkName: "component---src-pages-projects-colour-clock-jsx" */),
  "component---src-pages-projects-faster-phalanges-tsx": () => import("./../../../src/pages/projects/faster-phalanges.tsx" /* webpackChunkName: "component---src-pages-projects-faster-phalanges-tsx" */),
  "component---src-pages-projects-rnn-typing-practice-jsx": () => import("./../../../src/pages/projects/rnn-typing-practice.jsx" /* webpackChunkName: "component---src-pages-projects-rnn-typing-practice-jsx" */)
}

