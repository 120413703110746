import React from "react";
import {
  createMuiTheme,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { blueGrey, brown } from "@material-ui/core/colors";
import {
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";

import "katex/dist/katex.min.css";
import "@fontsource/roboto";
import "@fontsource/roboto-slab";

const GOLDEN_RATIO = (1 + Math.sqrt(5)) / 2;
const RATIO = Math.sqrt(GOLDEN_RATIO);
const TYPOGRAPHY_HEADER_BASE = {
  fontFamily: '"Roboto Slab", sans-serif',
  margin: "1.12em 0 0.5em",
};

const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: brown,
    type: "dark",
  },
  typography: {
    h1: {
      ...TYPOGRAPHY_HEADER_BASE,
      fontWeight: 100,
      fontSize: `${(RATIO ** 4).toFixed(2)}rem`,
    },
    h2: {
      ...TYPOGRAPHY_HEADER_BASE,
      fontWeight: 100,
      fontSize: `${(RATIO ** 3).toFixed(2)}rem`,
    },
    h3: {
      ...TYPOGRAPHY_HEADER_BASE,
      fontWeight: 200,
      fontSize: `${(RATIO ** 2).toFixed(2)}rem`,
    },
    h4: {
      ...TYPOGRAPHY_HEADER_BASE,
      fontWeight: 300,
      fontSize: `${(RATIO ** 1).toFixed(2)}rem`,
    },
    h5: {
      ...TYPOGRAPHY_HEADER_BASE,
      fontWeight: 500,
      fontSize: `${(RATIO ** 0).toFixed(2)}rem`,
    },
    h6: {
      ...TYPOGRAPHY_HEADER_BASE,
      fontWeight: 800,
      fontSize: `${(RATIO ** -1).toFixed(2)}rem`,
    },
  },
});

const GlobalStyle = createGlobalStyle`
  * {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  html {
      font-size: calc(0.618vw + 14px);
  }
`;

export const RootElementWrapper = ({ children }) => (
  <StyledThemeProvider theme={theme}>
    <GlobalStyle />
    <StylesProvider injectFirst={true}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StylesProvider>
  </StyledThemeProvider>
);
