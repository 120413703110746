module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BayanBennett.com","short_name":"BayanBennett","start_url":"/","background_color":"#bfbbfb","theme_color":"#bfb","display":"minimal-ui","display_override":["tabbed","minimal-ui"],"icon":"src/images/bayan-logo.png","capture_links":"existing_client_event","url_handlers":[{"origin":"*.bayanbennett.com"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"00642af7b45298cf550496c044ab3777"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-43630920-1"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
