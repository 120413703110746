import React from "react";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";

import Header from "./header";

const query = graphql`
  query {
    basicJson {
      title
      social {
        name
        href
        color
      }
    }
    bayanLogo: file(name: { eq: "bayan-logo" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default () => {
  const data = useStaticQuery(query);
  const {
    basicJson: { title, social },
    bayanLogo,
  } = data;
  return (
    <Header
      siteTitle={title}
      social={social}
      logo={bayanLogo.childImageSharp}
    />
  );
};
