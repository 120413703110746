import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiAvatar from "@material-ui/core/Avatar";
import {
  faFlickr,
  faGithub,
  faGooglePlusG,
  faInstagram,
  faLinkedinIn,
  faSoundcloud,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const iconMap = {
  Twitter: faTwitter,
  Flickr: faFlickr,
  GitHub: faGithub,
  "Google+": faGooglePlusG,
  Instagram: faInstagram,
  LinkedIn: faLinkedinIn,
  SoundCloud: faSoundcloud,
  YouTube: faYoutube,
};

const SocialButton = styled(MuiAvatar)`
  color: ${(p) => p.iconColor};
  border: none;
  background: none;
`;

const Social = ({ links }) => (
  <div style={{ flexDirection: "row", display: "flex" }}>
    {links.map(({ href, name, color }) => (
      <SocialButton
        component="a"
        aria-label={name}
        iconColor={color}
        key={name}
        variant="square"
        id={name}
        href={href}
        target="_blank"
        rel="noopener noreferrer external"
      >
        <FontAwesomeIcon icon={iconMap[name]} />
      </SocialButton>
    ))}
  </div>
);

export default Social;
