import React from "react";
import Header from "../components/header";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreativeCommons,
  faCreativeCommonsBy,
  faCreativeCommonsNc,
  faCreativeCommonsSa,
} from "@fortawesome/free-brands-svg-icons";
import { Typography, Tooltip } from "@material-ui/core";
import { RootElementWrapper } from "./root-element-wrapper";

const Body = styled.main`
  overflow: hidden;
  padding: 1rem calc(5vw - 0.5rem);
  //padding: 1rem calc(30vw - 6rem);
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  padding: 0 calc(10vw - 1rem);
`;

const License = styled.span`
  color: ${(p) => p.theme.palette.text.primary};
`;

const Copyright = styled(License)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const PageElementWrapper = ({ children }) => (
  <RootElementWrapper>
    <Header />
    <Body>{children}</Body>
    <Footer>
      <Tooltip title="Except where otherwise noted, this work is licensed under a Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International license by the author.">
        <License>
          <FontAwesomeIcon icon={faCreativeCommons} />
          <FontAwesomeIcon icon={faCreativeCommonsBy} />
          <FontAwesomeIcon icon={faCreativeCommonsNc} />
          <FontAwesomeIcon icon={faCreativeCommonsSa} />
        </License>
      </Tooltip>
      <Copyright>
        <Typography variant="caption">
          © {new Date().getFullYear()} Bayan Bennett
        </Typography>
      </Copyright>
    </Footer>
  </RootElementWrapper>
);
