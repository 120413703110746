import React from "react";
import PropTypes from "prop-types";
import MuiAppBar from "@material-ui/core/AppBar";
import MuiToolbar from "@material-ui/core/Toolbar";
import MuiAvatar from "@material-ui/core/Avatar";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

import Social from "./social";

const Link = styled(GatsbyLink)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  color: unset;
  :visited {
    color: unset;
  }
`;

const Avatar = styled(MuiAvatar)`
  width: 3rem;
  height: 3rem;
`;

const AppBar = styled(MuiAppBar)`
  border-radius: 0 0 4px 4px;
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

const Header = ({ siteTitle, social, logo }) => (
  <>
    <AppBar position="static" color="default">
      <Toolbar>
        <Link to="/">
          <Avatar
            variant="square"
            alt={siteTitle}
            src={logo.fluid.src}
            srcSet={logo.fluid.srcSet}
          />
        </Link>
        <Social links={social} />
      </Toolbar>
    </AppBar>
  </>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
